import React from "react"
import { Link } from "gatsby"

/*
|--------------------------------------------------------------------------
| Menu
|--------------------------------------------------------------------------
*/

import Haus from "./images/haus"
import Apartments from "./images/apartments"
import Umgebung from "./images/umgebung"

const Menu = () => (
  <section
    style={{
      backgroundColor: "#fafafa",
      paddingTop: "0.75rem",
    }}
    className="section"
  >
    <div className="container has-text-centered">
      <br />
      <h3 className="title is-3">Urlaub am Turnersee</h3>
      <h5 className="subtitle is-5">
        Genießen Sie ein paar unbeschwerte Tage am Turnersee...
      </h5>
      Ein perfekter Urlaub für die ganze Familie in einer traumhaften Landschaft
      im Sonnenwinkel Kärntens.
      <br />
      Ob Sie nur Erholung in den Bergen, Entspannung an den wunderschönen
      Badeseen oder Abwechslung bei den vielen Ausflugszielen in der Region
      suchen - für jeden ist etwas dabei.
      <br />
      <br />
      <br />
    </div>

    <div className="slider container">
      <div className="card">
        <Link to="/haus/">
          <Haus />
          <div className="card-content">
            <div className="title is-5">Unser Haus</div>
            Wenn Sie einen gemütlichen, erholsamen Urlaub verbunden mit Spiel,
            Sport und Spass in familiärer, kinderfreundlicher Atmosphäre erleben
            möchten, dann sind Sie bei uns genau richtig.
          </div>
        </Link>
      </div>
      <div className="card">
        <Apartments />
        <div className="card-content">
          <div className="title is-5">Die Apartments</div>
          Wir bieten Ihnen drei komplett eingerichtete Ferienwohnungen für 2 bis
          6 Personen.
        </div>
      </div>
      <div className="card">
        <Umgebung />
        <div className="card-content">
          <div className="title is-5">Die Ferienregion</div>
          Die Region bietet zahlreiche Möglichkeiten für das Laufen, Biken,
          Reiten, Golfen und Wandern mit jeder Menge an Ausflugszielen.
        </div>
      </div>
    </div>
  </section>
)

export default Menu
